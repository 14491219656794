import * as React from "react"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles>
      <form name="contact" action="/msgreply" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <input placeholder="Вашето име" type="text" name="name" />
        <input placeholder="Имейл" type="email" name="email" />
        <textarea placeholder="Съобщение" name="message" rows="5"></textarea>
        <button className="btn" type="submit">
          <bttn>  Изпрати  </bttn>
        </button>
      </form>
    </ContactStyles>
  )
}

export default Contact
