import * as React from "react"
import Layout from "../components/Layout"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import { Link } from "gatsby"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const contact = () => {
  return (
    <>
      <Seo title="contact" />
      <Layout>
        <BlogSingleStyles>
            <h1 className="blogsingle__title">Пишете ни</h1>          
            <article className="blogsingle__content">
              <p>Можете да ни откриете във <a href="https://www.facebook.com/venzel.bg"><strong>Facebook</strong>
              </a>, <a href="https://www.instagram.com/venzel.bg/"><strong>Instagram</strong>
              </a>, да ни пишете на имейл /<strong><em>venzelworkbg@gmail.com</em>
              </strong>/, или да изпратите своето запитване във формата ни за контакти. </p>
              <Contact />
              <div className="blogsingle__back">
                <Button text="Към начална страница" as={Link} to="/" />
              </div>
            </article>
        </BlogSingleStyles>
      </Layout>
    </>
  )
}

export default contact
